const pathData1 =
  "M11.375,2.188 C11.616,2.188 11.812,2.384 11.812,2.625 L11.812,11.375 C11.812,11.616 11.616,11.812 11.375,11.812 L2.625,11.812 C2.384,11.812 2.188,11.616 2.188,11.375 L2.188,2.625 C2.188,2.384 2.384,2.188 2.625,2.188 L11.375,2.188 Z M2.625,0.875 C1.66,0.875 0.875,1.66 0.875,2.625 L0.875,11.375 C0.875,12.34 1.66,13.125 2.625,13.125 L11.375,13.125 C12.34,13.125 13.125,12.34 13.125,11.375 L13.125,2.625 C13.125,1.66 12.34,0.875 11.375,0.875 L2.625,0.875 Z"
const pathData2 =
  "M5.508,7.647 L6.209,9.49 C6.309,9.754 6.177,10.051 5.912,10.152 C5.649,10.252 5.352,10.12 5.252,9.855 L4.483,7.833 L3.893,7.833 L3.893,9.673 C3.893,9.957 3.664,10.186 3.381,10.186 C3.098,10.186 2.869,9.957 2.869,9.673 L2.869,4.327 C2.869,4.043 3.098,3.814 3.381,3.814 L4.663,3.814 C5.771,3.814 6.67,4.714 6.67,5.824 C6.67,6.632 6.193,7.328 5.508,7.647 Z M11.132,4.316 L11.132,9.684 C11.132,9.96 10.908,10.184 10.632,10.184 C10.356,10.184 10.132,9.96 10.132,9.684 L10.132,6.886 L9.484,8.486 C9.407,8.677 9.222,8.801 9.016,8.799 C8.811,8.797 8.627,8.669 8.554,8.477 L7.999,7.025 L7.999,9.684 C7.999,9.96 7.775,10.184 7.499,10.184 C7.223,10.184 6.999,9.96 6.999,9.684 L6.999,4.316 C6.999,4.075 7.171,3.868 7.408,3.825 C7.645,3.781 7.88,3.913 7.966,4.138 L9.034,6.933 L10.168,4.129 C10.258,3.907 10.492,3.78 10.727,3.826 C10.962,3.871 11.132,4.077 11.132,4.316 Z M3.893,6.808 L4.663,6.808 C5.206,6.808 5.646,6.368 5.646,5.824 C5.646,5.28 5.206,4.839 4.663,4.839 L3.893,4.839 Z"

const definition = {
  prefix: "fas",
  iconName: "square-ringgit-sign",
  icon: [14, 14, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facSquareRinggitSign }
