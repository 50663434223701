const facBoxUnarchive = {
  prefix: "fas",
  iconName: "box-unarchive",
  icon: [
    19,
    14,
    [],
    null,
    "M1.811,0.139 L3.18,1.212 C3.242,1.198 3.308,1.19 3.375,1.19 L15.625,1.19 C16.09,1.19 16.5,1.601 16.5,2.065 L16.5,4.253 C16.5,4.745 16.09,5.128 15.625,5.128 L8.176,5.128 L9.85,6.44 L11.469,6.44 C11.824,6.44 12.125,6.741 12.125,7.097 C12.125,7.46 11.854,7.725 11.522,7.751 L14.312,9.938 L14.312,6.003 L15.625,6.003 L15.625,10.967 L17.998,12.827 C18.283,13.051 18.335,13.464 18.111,13.748 C17.886,14.033 17.473,14.085 17.189,13.861 L1.002,1.173 C0.717,0.949 0.665,0.536 0.889,0.251 C1.114,-0.033 1.527,-0.085 1.811,0.139 Z M14.418,13.355 C14.247,13.41 14.064,13.44 13.875,13.44 L5.125,13.44 C4.141,13.44 3.375,12.675 3.375,11.69 L3.375,6.003 L4.687,6.003 L4.687,11.69 C4.687,11.936 4.879,12.128 5.125,12.128 L12.852,12.128 Z M4.826,2.503 L6.501,3.815 L15.187,3.815 L15.187,2.503 Z M2.5,4.014 L3.921,5.128 L3.375,5.128 C2.883,5.128 2.5,4.745 2.5,4.253 Z",
  ],
}

export { facBoxUnarchive }
