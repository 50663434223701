const pathData1 =
  "M0.708594 0.872559C0.349609 0.872559 0.0585938 1.16357 0.0585938 1.52256V6.7734V12.4706C0.0585938 12.8295 0.349609 13.1206 0.708594 13.1206C1.06758 13.1206 1.35859 12.8295 1.35859 12.4706V7.4234H3.76887C4.12786 7.4234 4.41887 7.13238 4.41887 6.7734C4.41887 6.41441 4.12786 6.12339 3.76887 6.12339H1.35859V2.17256H4.64696C5.00594 2.17256 5.29696 1.88154 5.29696 1.52256C5.29696 1.16357 5.00594 0.872559 4.64696 0.872559H0.708594ZM7.97784 4.49521C7.97784 4.13623 7.68682 3.84521 7.32784 3.84521C6.96885 3.84521 6.67784 4.13623 6.67784 4.49521V6.10957H5.95371C5.59472 6.10957 5.30371 6.40058 5.30371 6.75957C5.30371 7.11855 5.59472 7.40957 5.95371 7.40957H6.67784V11.183C6.67784 11.7875 6.97281 12.3041 7.46759 12.6457C7.94309 12.9739 8.57685 13.1273 9.29079 13.1273C9.64977 13.1273 9.94079 12.8363 9.94079 12.4773C9.94079 12.1183 9.64977 11.8273 9.29079 11.8273C8.74485 11.8273 8.39714 11.7077 8.20613 11.5758C8.03441 11.4573 7.97784 11.3268 7.97784 11.183V7.40957H8.7942C9.15318 7.40957 9.4442 7.11855 9.4442 6.75957C9.4442 6.40058 9.15318 6.10957 8.7942 6.10957H7.97784V4.49521Z"

const definition = {
  prefix: "fas",
  iconName: "hungarian-forint-sign",
  icon: [10, 14, [], null, pathData1],
}

export { definition as facHungarianForintSign }
