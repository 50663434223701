const pathData1 =
  "M3.80488 0.950537C3.80488 0.591552 3.51387 0.300537 3.15488 0.300537C2.7959 0.300537 2.50488 0.591552 2.50488 0.950537V7.93081C2.50488 8.2898 2.7959 8.58081 3.15488 8.58081C3.51387 8.58081 3.80488 8.2898 3.80488 7.93081V0.950537Z"
const pathData2 =
  "M2.70813 11.3193C2.80971 11.1981 2.96503 11.1334 3.15408 11.1407C3.35464 11.1485 3.52064 11.2359 3.6012 11.3285C3.83688 11.5993 4.24745 11.6277 4.51823 11.3921C4.78902 11.1564 4.81748 10.7458 4.58179 10.475C4.23389 10.0753 3.71154 9.86144 3.20465 9.84171C2.68625 9.82153 2.11501 10.003 1.71168 10.4844C1.241 11.0461 1.11897 11.7709 1.33242 12.3995H1.25059C0.891601 12.3995 0.600586 12.6905 0.600586 13.0495C0.600586 13.4085 0.891601 13.6995 1.25059 13.6995H3.26069L4.44678 13.6995C4.80577 13.6995 5.09679 13.4085 5.09679 13.0495C5.0968 12.6905 4.80579 12.3995 4.4468 12.3995L3.26069 12.3995C2.86024 12.3995 2.66282 12.2127 2.58225 12.0299C2.49351 11.8286 2.51097 11.5546 2.70813 11.3193Z"
const pathData3 =
  "M10.3736 2.92324C10.6702 2.72093 11.0746 2.79734 11.2769 3.09389L13.0278 5.66046L13.0302 5.664L13.0302 5.66401C13.4981 6.35975 13.4941 7.10511 13.1665 7.68369C12.8604 8.22436 12.275 8.60153 11.6429 8.60149H10.168H10.1568L10.1568 8.6014C9.95431 8.5979 9.43143 8.54177 8.96787 8.12883C8.69981 7.89005 8.67608 7.47918 8.91487 7.21112C9.15365 6.94307 9.56452 6.91934 9.83258 7.15812C9.96986 7.28041 10.1319 7.29989 10.1757 7.30149H11.6429C11.7635 7.3015 11.9378 7.21534 12.0352 7.04317C12.1111 6.90913 12.1547 6.69264 11.9526 6.39112L10.203 3.8265C10.0007 3.52995 10.0771 3.12555 10.3736 2.92324ZM6.6673 8.59961C7.20573 8.59961 7.64221 8.16313 7.64221 7.62469C7.64221 7.08626 7.20573 6.64978 6.6673 6.64978C6.12887 6.64978 5.69238 7.08626 5.69238 7.62469C5.69238 8.16313 6.12887 8.59961 6.6673 8.59961Z"

const definition = {
  prefix: "fas",
  iconName: "uae-dirham-sign",
  icon: [13, 14, [], null, `${pathData1} ${pathData2} ${pathData3}`],
}

export { definition as facUaeDirhamSign }
