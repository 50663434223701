const pathData =
  "M4.017,7.517 L6.495,5.039 C6.783,4.751 7.248,4.751 7.536,5.039 C7.823,5.326 7.823,5.792 7.536,6.079 L5.444,8.171 L7.953,13.047 C8.139,13.409 7.997,13.852 7.636,14.038 C7.275,14.224 6.831,14.082 6.645,13.721 L4.368,9.295 L3.732,9.974 L3.732,13.384 C3.732,13.79 3.403,14.12 2.996,14.12 C2.59,14.12 2.261,13.79 2.261,13.384 L2.261,2.616 C2.261,2.209 2.59,1.88 2.996,1.88 C3.403,1.88 3.732,2.209 3.732,2.616 L3.732,7.821 Z M13.004,6.328 C11.483,6.328 10.199,7.498 10.199,9.23 L10.199,13.384 C10.199,13.79 9.87,14.12 9.463,14.12 C9.057,14.12 8.727,13.79 8.727,13.384 L8.727,5.592 C8.727,5.186 9.057,4.857 9.463,4.857 C9.87,4.857 10.199,5.186 10.199,5.592 L10.199,5.895 C10.955,5.237 11.941,4.857 13.004,4.857 C13.41,4.857 13.739,5.186 13.739,5.592 C13.739,5.999 13.41,6.328 13.004,6.328 Z M12.5,14 C11.948,14 11.5,13.552 11.5,13 C11.5,12.448 11.948,12 12.5,12 C13.052,12 13.5,12.448 13.5,13 C13.5,13.552 13.052,14 12.5,14 Z"

const definition = {
  prefix: "fas",
  iconName: "danish-krone-sign",
  icon: [17, 16, [], null, pathData],
}

export { definition as facDanishKroneSign }
