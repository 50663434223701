const pathData1 =
  "M5.578,8.2 L5.578,10.763 C5.578,11.039 5.801,11.263 6.078,11.263 C6.354,11.263 6.577,11.039 6.577,10.763 L6.577,5.513 C6.577,5.236 6.354,5.013 6.078,5.013 C5.801,5.013 5.578,5.236 5.578,5.513 L5.578,7.2 L4.802,7.213 L4.802,5.525 C4.802,5.249 4.579,5.025 4.303,5.025 C4.026,5.025 3.803,5.249 3.803,5.525 L3.803,10.775 C3.803,11.052 4.026,11.275 4.303,11.275 C4.579,11.275 4.802,11.052 4.802,10.775 L4.802,8.213 L5.578,8.2 Z"
const pathData2 =
  "M9.233,7.824 L10.057,5.978 C10.123,5.825 10.181,5.661 10.186,5.513 C10.191,5.359 10.139,5.217 9.985,5.121 C9.842,5.031 9.702,5.011 9.577,5.064 C9.454,5.115 9.359,5.229 9.29,5.378 L8.393,7.385 L8.266,7.718 L8.266,5.525 C8.266,5.249 8.044,5.025 7.769,5.025 C7.494,5.025 7.272,5.249 7.272,5.525 L7.272,10.775 C7.272,11.051 7.494,11.275 7.769,11.275 C8.044,11.275 8.266,11.051 8.266,10.775 L8.266,9.325 L8.513,8.758 L9.329,11.049 L9.33,11.052 C9.393,11.184 9.515,11.256 9.644,11.275 C9.773,11.295 9.914,11.265 10.026,11.187 C10.256,11.028 10.274,10.737 10.158,10.503 L9.233,7.824 Z"
const pathData3 =
  "M10.901,9.338 C10.662,9.201 10.359,9.285 10.222,9.524 C10.085,9.763 10.169,10.067 10.407,10.204 L10.758,10.405 C10.928,10.503 11.111,10.576 11.299,10.623 L11.299,11.022 C11.299,11.298 11.521,11.521 11.797,11.521 C12.072,11.521 12.295,11.298 12.295,11.022 L12.295,10.638 C12.952,10.463 13.438,9.862 13.438,9.148 C13.438,8.5 13.034,7.92 12.427,7.699 L11.508,7.365 C11.424,7.334 11.35,7.283 11.293,7.219 C11.205,7.121 11.153,6.993 11.153,6.855 C11.153,6.644 11.274,6.461 11.449,6.371 C11.523,6.334 11.606,6.312 11.694,6.312 L11.853,6.312 C11.958,6.312 12.062,6.324 12.163,6.348 C12.231,6.364 12.298,6.385 12.363,6.411 L12.549,6.485 C12.804,6.587 13.094,6.463 13.196,6.206 C13.297,5.951 13.173,5.66 12.918,5.558 L12.734,5.483 C12.592,5.426 12.445,5.383 12.296,5.355 L12.296,4.978 C12.296,4.702 12.074,4.479 11.799,4.479 L11.798,4.479 C11.523,4.479 11.302,4.702 11.302,4.978 L11.302,5.363 C10.644,5.538 10.159,6.139 10.159,6.854 C10.159,7.475 10.53,8.034 11.098,8.274 C11.121,8.284 11.145,8.293 11.17,8.302 L12.088,8.637 C12.301,8.714 12.443,8.918 12.443,9.146 C12.443,9.446 12.2,9.689 11.902,9.689 L11.816,9.689 C11.617,9.689 11.423,9.637 11.252,9.539 L10.901,9.338 Z"
const pathData4 =
  "M3.531,1 L13.469,1 C14.591,1 15.5,1.909 15.5,3.031 L15.5,12.969 C15.5,14.091 14.591,15 13.469,15 L3.531,15 C2.409,15 1.5,14.091 1.5,12.969 L1.5,3.031 C1.5,1.909 2.409,1 3.531,1 Z M13.469,2.531 L3.531,2.531 C3.255,2.531 3.031,2.755 3.031,3.031 L3.031,12.969 C3.031,13.245 3.255,13.469 3.531,13.469 L13.469,13.469 C13.745,13.469 13.969,13.245 13.969,12.969 L13.969,3.031 C13.969,2.755 13.745,2.531 13.469,2.531 Z"

const definition = {
  prefix: "fas",
  iconName: "square-hong-kong-dollar-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2} ${pathData3} ${pathData4}`],
}

export { definition as facSquareHongKongDollarSign }
