const pathData1 =
  "M3.03,1.453 C3.153,1.323 3.323,1.25 3.501,1.25 L6.81,1.25 C7.169,1.25 7.46,1.541 7.46,1.9 L7.46,9.357 L7.523,9.357 C7.882,9.357 8.173,9.648 8.173,10.007 L8.173,12.1 C8.173,12.459 7.882,12.75 7.523,12.75 C7.164,12.75 6.873,12.459 6.873,12.1 L6.873,10.657 L2.173,10.657 L2.173,12.1 C2.173,12.459 1.882,12.75 1.523,12.75 C1.164,12.75 0.873,12.459 0.873,12.1 L0.873,10.007 C0.873,9.648 1.164,9.357 1.523,9.357 L1.744,9.357 C2.385,8.111 2.697,7.08 2.837,6.018 C2.992,4.841 2.941,3.605 2.852,1.934 C2.843,1.756 2.907,1.582 3.03,1.453 Z M13.704,3.428 L13.704,10.737 C13.704,11.096 13.413,11.387 13.054,11.387 C12.695,11.387 12.404,11.096 12.404,10.737 L12.404,6.123 L9.889,11.033 C9.752,11.3 9.45,11.439 9.158,11.368 C8.866,11.298 8.66,11.037 8.66,10.737 L8.66,3.428 C8.66,3.069 8.951,2.778 9.31,2.778 C9.669,2.778 9.96,3.069 9.96,3.428 L9.96,8.042 L12.476,3.132 C12.613,2.864 12.915,2.726 13.207,2.796 C13.498,2.867 13.704,3.128 13.704,3.428 Z M15.644,3.428 L15.644,6.432 L18.028,6.432 L18.028,3.428 C18.028,3.069 18.319,2.778 18.678,2.778 C19.037,2.778 19.328,3.069 19.328,3.428 L19.328,10.737 C19.328,11.096 19.037,11.387 18.678,11.387 C18.319,11.387 18.028,11.096 18.028,10.737 L18.028,7.732 L15.644,7.732 L15.644,10.737 C15.644,11.096 15.353,11.387 14.994,11.387 C14.635,11.387 14.344,11.096 14.344,10.737 L14.344,3.428 C14.344,3.069 14.635,2.778 14.994,2.778 C15.353,2.778 15.644,3.069 15.644,3.428 Z M3.191,9.357 L6.16,9.357 L6.16,2.55 L4.185,2.55 C4.25,3.9 4.275,5.059 4.126,6.188 C3.988,7.233 3.705,8.24 3.191,9.357 Z M20.438,11.685 C20.057,11.685 19.749,11.377 19.749,10.996 C19.749,10.615 20.057,10.306 20.438,10.306 C20.819,10.306 21.127,10.615 21.127,10.996 C21.127,11.377 20.819,11.685 20.438,11.685 Z"

const definition = {
  prefix: "fas",
  iconName: "serbian-dinar-sign",
  icon: [22, 14, [], null, pathData1],
}

export { definition as facSerbianDinarSign }
