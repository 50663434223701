const pathData1 =
  "M11.375 2.1875C11.6156 2.1875 11.8125 2.38437 11.8125 2.625V11.375C11.8125 11.6156 11.6156 11.8125 11.375 11.8125H2.625C2.38437 11.8125 2.1875 11.6156 2.1875 11.375V2.625C2.1875 2.38437 2.38437 2.1875 2.625 2.1875H11.375ZM2.625 0.875C1.65977 0.875 0.875 1.65977 0.875 2.625V11.375C0.875 12.3402 1.65977 13.125 2.625 13.125H11.375C12.3402 13.125 13.125 12.3402 13.125 11.375V2.625C13.125 1.65977 12.3402 0.875 11.375 0.875H2.625Z"
const pathData2 =
  "M10.8492 4.56353C10.9534 4.21998 10.7592 3.85707 10.4157 3.75296C10.0721 3.64885 9.70923 3.84296 9.60512 4.18652L8.76108 6.9718H8.46878L7.74206 4.20964C7.66687 3.92387 7.40839 3.72477 7.11289 3.72503C6.81739 3.72528 6.55925 3.92483 6.48456 4.21073L5.76323 6.9718H5.46996L4.61934 4.18526C4.51453 3.84191 4.15123 3.64854 3.80788 3.75335C3.46454 3.85816 3.27117 4.22146 3.37598 4.5648L4.11074 6.9718H3.64434C3.39581 6.9718 3.19434 7.17327 3.19434 7.4218C3.19434 7.67033 3.39581 7.8718 3.64434 7.8718H4.38547L5.05491 10.0648C5.13981 10.3429 5.3991 10.5308 5.68983 10.5249C5.98056 10.519 6.23198 10.3207 6.30548 10.0393L6.87174 7.8718H7.36132L7.93188 10.0404C8.00587 10.3217 8.25769 10.5195 8.54847 10.5249C8.83925 10.5303 9.09821 10.3419 9.18255 10.0635L9.84672 7.8718H10.6053C10.8538 7.8718 11.0553 7.67033 11.0553 7.4218C11.0553 7.17327 10.8538 6.9718 10.6053 6.9718H10.1195L10.8492 4.56353ZM7.12454 6.9718L7.11567 6.9381L7.10687 6.9718H7.12454Z"

const definition = {
  prefix: "fas",
  iconName: "square-won-sign",
  icon: [14, 14, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facSquareWonSign }
