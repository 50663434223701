const pathData1 =
  "M11.375 2.1875C11.6156 2.1875 11.8125 2.38437 11.8125 2.625V11.375C11.8125 11.6156 11.6156 11.8125 11.375 11.8125H2.625C2.38437 11.8125 2.1875 11.6156 2.1875 11.375V2.625C2.1875 2.38437 2.38437 2.1875 2.625 2.1875H11.375ZM2.625 0.875C1.65977 0.875 0.875 1.65977 0.875 2.625V11.375C0.875 12.3402 1.65977 13.125 2.625 13.125H11.375C12.3402 13.125 13.125 12.3402 13.125 11.375V2.625C13.125 1.65977 12.3402 0.875 11.375 0.875H2.625Z"
const pathData2 =
  "M4.43066 3.35012C4.07168 3.35012 3.78066 3.64114 3.78066 4.00012V6.87603V9.9964C3.78066 10.3554 4.07168 10.6464 4.43066 10.6464C4.78965 10.6464 5.08066 10.3554 5.08066 9.9964V7.52603H6.10679C6.46578 7.52603 6.75679 7.23502 6.75679 6.87603C6.75679 6.51705 6.46578 6.22603 6.10679 6.22603H5.08066V4.65012H6.58772C6.94671 4.65012 7.23772 4.35911 7.23772 4.00012C7.23772 3.64114 6.94671 3.35012 6.58772 3.35012H4.43066ZM9.14773 5.6283C9.14773 5.26931 8.85672 4.9783 8.49773 4.9783C8.13875 4.9783 7.84773 5.26931 7.84773 5.6283V6.21849H7.74512C7.38613 6.21849 7.09512 6.50951 7.09512 6.86849C7.09512 7.22748 7.38613 7.51849 7.74512 7.51849H7.84773V9.29121C7.84773 9.72649 8.06321 10.0968 8.40726 10.3343C8.73203 10.5585 9.14383 10.6501 9.57285 10.6501C9.93184 10.6501 10.2229 10.3591 10.2229 10.0001C10.2229 9.64115 9.93184 9.35014 9.57285 9.35014C9.31589 9.35014 9.19001 9.29404 9.14773 9.26576V7.51849H9.30087C9.65985 7.51849 9.95087 7.22748 9.95087 6.86849C9.95087 6.50951 9.65985 6.21849 9.30087 6.21849H9.14773V5.6283Z"

const definition = {
  prefix: "fas",
  iconName: "square-hungarian-forint-sign",
  icon: [14, 14, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facSquareHungarianForintSign }
