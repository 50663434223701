const pathData1 =
  "M11.375 2.1875C11.6156 2.1875 11.8125 2.38437 11.8125 2.625V11.375C11.8125 11.6156 11.6156 11.8125 11.375 11.8125H2.625C2.38437 11.8125 2.1875 11.6156 2.1875 11.375V2.625C2.1875 2.38437 2.38437 2.1875 2.625 2.1875H11.375ZM2.625 0.875C1.65977 0.875 0.875 1.65977 0.875 2.625V11.375C0.875 12.3402 1.65977 13.125 2.625 13.125H11.375C12.3402 13.125 13.125 12.3402 13.125 11.375V2.625C13.125 1.65977 12.3402 0.875 11.375 0.875H2.625Z"
const pathData2 =
  "M4.33105 3.49937C3.99968 3.49937 3.73105 3.76799 3.73105 4.09937V9.90029C3.73105 10.2317 3.99968 10.5003 4.33105 10.5003C4.66243 10.5003 4.93105 10.2317 4.93105 9.90029V4.69937H6.42915C6.81223 4.69937 7.21288 5.00879 7.21288 5.51684V8.1625C7.21288 8.49387 7.4815 8.7625 7.81288 8.7625C8.14425 8.7625 8.41288 8.49387 8.41288 8.1625V5.51684C8.41288 4.27453 7.40112 3.49937 6.42915 3.49937H4.33105ZM9.06797 8.5115C9.06797 8.86479 8.80129 9.30039 8.20827 9.30039H8.20827L6.78615 9.30038V5.95247C6.78615 5.6211 6.51752 5.35247 6.18615 5.35247C5.85478 5.35247 5.58615 5.6211 5.58615 5.95247V9.90038C5.58615 10.2317 5.85477 10.5004 6.18614 10.5004L8.20826 10.5004H8.20827C9.5818 10.5004 10.268 9.40329 10.268 8.5115V4.09947C10.268 3.76809 9.99934 3.49947 9.66797 3.49947C9.3366 3.49947 9.06797 3.76809 9.06797 4.09947V8.5115Z"

const definition = {
  prefix: "fas",
  iconName: "square-israeli-new-shekel-sign",
  icon: [14, 14, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facSquareIsraeliNewShekelSign }
