const pathData1 =
  "M1.05957 12.9595C1.05957 12.6005 1.35059 12.3095 1.70957 12.3095H8.69492C9.05391 12.3095 9.34492 12.6005 9.34492 12.9595C9.34492 13.3185 9.05391 13.6095 8.69492 13.6095H1.70957C1.35059 13.6095 1.05957 13.3185 1.05957 12.9595Z"
const pathData2 =
  "M5.78581 1.69038C3.7278 1.69038 1.95527 3.39692 1.95527 5.41457C1.95527 7.43635 3.73207 9.1606 5.78581 9.1606C6.66426 9.1606 7.61652 8.87413 8.32332 8.38164C8.61786 8.17641 9.02299 8.2488 9.22822 8.54334C9.43345 8.83787 9.36106 9.24301 9.06652 9.44824C8.12988 10.1009 6.91528 10.4606 5.78581 10.4606C3.04627 10.4606 0.655273 8.18607 0.655273 5.41457C0.655273 2.63893 3.05053 0.390381 5.78581 0.390381C6.89781 0.390381 8.09637 0.687681 9.07778 1.40301C9.36789 1.61446 9.43165 2.02105 9.2202 2.31115C9.00875 2.60125 8.60216 2.66502 8.31206 2.45357C7.59649 1.93201 6.68173 1.69038 5.78581 1.69038Z"

const definition = {
  prefix: "fas",
  iconName: "som-sign",
  icon: [10, 14, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facSomSign }
