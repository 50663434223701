// NOTE: This svg was generated by taking the figma svg output and running it
// through the picosvg tool to convert it to simpler path strings. For future
// reference, if your svg has more complex rules like clip-rule="even-odd",
// etc., you may want to go with this simplification process.
const svgDataItems = [
  "M9.391,2.531 C9.114,2.531 8.891,2.755 8.891,3.031 C8.891,3.307 9.114,3.531 9.391,3.531 L11.391,3.531 C11.667,3.531 11.891,3.307 11.891,3.031 C11.891,2.755 11.667,2.531 11.391,2.531 L9.391,2.531 Z",
  "M8.891,5.031 C8.891,4.755 9.114,4.531 9.391,4.531 L11.391,4.531 C11.667,4.531 11.891,4.755 11.891,5.031 C11.891,5.307 11.667,5.531 11.391,5.531 L9.391,5.531 C9.114,5.531 8.891,5.307 8.891,5.031 Z",
  "M9.391,6.531 C9.114,6.531 8.891,6.755 8.891,7.031 C8.891,7.307 9.114,7.531 9.391,7.531 L11.391,7.531 C11.667,7.531 11.891,7.307 11.891,7.031 C11.891,6.755 11.667,6.531 11.391,6.531 L9.391,6.531 Z",
  "M2,11 C0.895,11 0,10.105 0,9 L0,2 C0,0.895 0.895,0 2,0 L12,0 C13.105,0 14,0.895 14,2 L14,9 C14,10.105 13.105,11 12,11 Z M2,1.3 C1.613,1.3 1.3,1.613 1.3,2 L1.3,9 C1.3,9.387 1.613,9.7 2,9.7 L6.906,9.7 L6.906,1.3 Z M12,1.3 L8.206,1.3 L8.206,9.7 L12,9.7 C12.387,9.7 12.7,9.387 12.7,9 L12.7,2 C12.7,1.613 12.387,1.3 12,1.3 Z",
]

const svgPathData = `${svgDataItems[0]} ${svgDataItems[1]} ${svgDataItems[2]} ${svgDataItems[3]}`

const definition = {
  prefix: "fas",
  iconName: "sidebar-flip-custom",
  icon: [14, 14, [], null, svgPathData],
}

export { definition as facSidebarFlip, svgPathData }
