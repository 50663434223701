const pathData1 =
  "M6.72949 0.806649L6.42871 1.98243C6.70215 2.00977 6.97559 2.06446 7.24902 2.11915L7.63184 0.505868C7.71387 0.150399 8.06934 -0.0683511 8.4248 0.0136802C8.78027 0.0957114 8.99902 0.45118 8.91699 0.806649L8.47949 2.58399C8.69824 2.69337 8.88965 2.83009 9.08105 2.96681C9.38184 3.18556 9.46387 3.59571 9.24512 3.86915C9.02637 4.16993 8.61621 4.25196 8.34277 4.03321C8.28809 4.00587 8.20605 3.95118 8.15137 3.92384L6.53809 10.6777C7.19434 10.5684 7.82324 10.3223 8.34277 9.96681C8.64355 9.74806 9.02637 9.83009 9.24512 10.1309C9.46387 10.4316 9.38184 10.8145 9.08105 11.0332C8.28809 11.6074 7.27637 11.9629 6.20996 12.0176L5.85449 13.4941C5.77246 13.8496 5.41699 14.0684 5.06152 13.9863C4.70605 13.9043 4.4873 13.5488 4.56934 13.1934L4.87012 11.9356C4.59668 11.8809 4.35059 11.7988 4.10449 11.7168L3.66699 13.4941C3.58496 13.8496 3.22949 14.0684 2.87402 13.9863C2.51855 13.9043 2.2998 13.5488 2.38184 13.1934L2.87402 11.1152C1.53418 10.2129 0.631836 8.70899 0.631836 6.98634C0.631836 4.47071 2.57324 2.41993 5.08887 2.03712L5.44434 0.505868C5.52637 0.150399 5.88184 -0.0683511 6.2373 0.0136802C6.59277 0.0957114 6.81152 0.45118 6.72949 0.806649ZM4.7334 3.43165V3.45899C3.09277 3.92384 1.94434 5.34571 1.94434 6.98634C1.94434 8.08009 2.43652 9.03712 3.22949 9.72071L4.7334 3.43165ZM4.40527 10.4316C4.65137 10.541 4.9248 10.5957 5.19824 10.6504L6.94824 3.40431C6.6748 3.34962 6.40137 3.29493 6.12793 3.29493L4.40527 10.4316Z"

const definition = {
  prefix: "fas",
  iconName: "colon-sign",
  icon: [14, 14, [], null, pathData1],
}

export { definition as facColonSign }
