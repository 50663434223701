const pathData1 =
  "M0.96875 0.875H4.90625C6.71094 0.875 8.1875 2.35156 8.1875 4.15625V9.1875C8.1875 9.57031 7.88672 9.84375 7.53125 9.84375C7.14844 9.84375 6.875 9.57031 6.875 9.1875V4.15625C6.875 3.08984 5.97266 2.1875 4.90625 2.1875H1.625V12.4688C1.625 12.8516 1.32422 13.125 0.96875 13.125C0.585938 13.125 0.3125 12.8516 0.3125 12.4688V1.53125C0.3125 1.17578 0.585938 0.875 0.96875 0.875ZM8.40625 13.125H4.46875C4.08594 13.125 3.8125 12.8516 3.8125 12.4688V5.03125C3.8125 4.67578 4.08594 4.375 4.46875 4.375C4.82422 4.375 5.125 4.67578 5.125 5.03125V11.8125H8.40625C9.47266 11.8125 10.375 10.9375 10.375 9.84375V1.53125C10.375 1.17578 10.6484 0.875 11.0312 0.875C11.3867 0.875 11.6875 1.17578 11.6875 1.53125V9.84375C11.6875 11.6758 10.2109 13.125 8.40625 13.125Z"

const definition = {
  prefix: "fas",
  iconName: "israeli-new-shekel-sign",
  icon: [12, 14, [], null, pathData1],
}

export { definition as facIsraeliNewShekelSign }
