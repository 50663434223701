const pathData1 =
  "M10.875 3.1875C11.1156 3.1875 11.3125 3.38437 11.3125 3.625V12.375C11.3125 12.6156 11.1156 12.8125 10.875 12.8125H2.125C1.88437 12.8125 1.6875 12.6156 1.6875 12.375V3.625C1.6875 3.38437 1.88437 3.1875 2.125 3.1875H10.875ZM2.125 1.875C1.15977 1.875 0.375 2.65977 0.375 3.625V12.375C0.375 13.3402 1.15977 14.125 2.125 14.125H10.875C11.8402 14.125 12.625 13.3402 12.625 12.375V3.625C12.625 2.65977 11.8402 1.875 10.875 1.875H2.125Z"
const pathData2 =
  "M4.75332 4.68875C4.75332 4.35737 4.48469 4.08875 4.15332 4.08875C3.82195 4.08875 3.55332 4.35737 3.55332 4.68875L3.55332 8.53591C3.55332 8.86728 3.82195 9.13591 4.15332 9.13591C4.48469 9.13591 4.75332 8.86728 4.75332 8.53591L4.75332 4.68875Z"
const pathData3 =
  "M4.13817 10.5589C4.14486 10.5075 4.16117 10.4899 4.1706 10.4812C4.18537 10.4675 4.2167 10.4483 4.27056 10.4387C4.32492 10.429 4.3835 10.4328 4.43133 10.4467C4.46704 10.4572 4.48254 10.4687 4.48567 10.4711L4.48618 10.4714C4.66129 10.6827 4.97438 10.7135 5.18731 10.5396C5.40121 10.365 5.43304 10.05 5.2584 9.83609C4.96966 9.48243 4.47761 9.38581 4.09455 9.4543C3.67086 9.53005 3.22375 9.83651 3.14654 10.4298C3.12341 10.6075 3.13497 10.7724 3.17471 10.9226C2.90812 10.9334 2.69531 11.1529 2.69531 11.4221C2.69531 11.6983 2.91917 11.9221 3.19531 11.9221H4.79974H5.08203C5.35817 11.9221 5.58203 11.6983 5.58203 11.4221C5.58203 11.146 5.35817 10.9221 5.08203 10.9221H4.79974C4.54209 10.9221 4.34384 10.8605 4.23538 10.7845C4.18577 10.7498 4.16263 10.718 4.15117 10.6934C4.14063 10.6708 4.12879 10.631 4.13817 10.5589ZM8.11442 5.23478C8.38816 5.04803 8.76146 5.11856 8.9482 5.3923L10.0708 7.0379L10.073 7.04117L10.073 7.04117C10.4103 7.54271 10.4133 8.09508 10.1678 8.52867C9.94214 8.92727 9.5089 9.21011 9.03147 9.21009H8.08582H8.07546L8.07546 9.21C7.92245 9.20736 7.53711 9.16585 7.19447 8.86062C6.94703 8.64021 6.92513 8.26094 7.14554 8.01351C7.36596 7.76607 7.74523 7.74417 7.99266 7.96458C8.01388 7.98349 8.03742 7.99517 8.06097 8.00246C8.07926 8.00813 8.09315 8.0097 8.09687 8.01009H9.03153C9.03133 8.01009 9.03135 8.01009 9.03156 8.01007C9.03317 8.00988 9.04644 8.00833 9.06709 7.99568C9.08995 7.98167 9.11037 7.96078 9.12359 7.93742C9.1356 7.91622 9.14159 7.89365 9.14033 7.86725C9.13914 7.84201 9.13016 7.78994 9.07822 7.71225L7.9569 6.06855C7.77016 5.79481 7.84068 5.42152 8.11442 5.23478ZM6.00771 9.02563C6.35293 9.02563 6.63279 8.74577 6.63279 8.40055C6.63279 8.05533 6.35293 7.77547 6.00771 7.77547C5.66248 7.77547 5.38263 8.05533 5.38263 8.40055C5.38263 8.74577 5.66248 9.02563 6.00771 9.02563Z"

const definition = {
  prefix: "fas",
  iconName: "square-uae-dirham-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2} ${pathData3}`],
}

export { definition as facSquareUaeDirhamSign }
