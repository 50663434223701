const pathData1 =
  "M11.375,2.188 C11.616,2.188 11.812,2.384 11.812,2.625 L11.812,11.375 C11.812,11.616 11.616,11.812 11.375,11.812 L2.625,11.812 C2.384,11.812 2.188,11.616 2.188,11.375 L2.188,2.625 C2.188,2.384 2.384,2.188 2.625,2.188 L11.375,2.188 Z M2.625,0.875 C1.66,0.875 0.875,1.66 0.875,2.625 L0.875,11.375 C0.875,12.34 1.66,13.125 2.625,13.125 L11.375,13.125 C12.34,13.125 13.125,12.34 13.125,11.375 L13.125,2.625 C13.125,1.66 12.34,0.875 11.375,0.875 L2.625,0.875 Z"
const pathData2 =
  "M3.889,4.282 L5.328,4.282 C5.604,4.282 5.828,4.506 5.828,4.782 L5.828,7.845 C6.01,7.919 6.138,8.098 6.138,8.307 L6.138,9.217 C6.138,9.493 5.914,9.717 5.638,9.717 C5.362,9.717 5.138,9.493 5.138,9.217 L5.138,8.807 L3.529,8.807 L3.529,9.217 C3.529,9.493 3.305,9.717 3.029,9.717 C2.753,9.717 2.529,9.493 2.529,9.217 L2.529,8.307 C2.529,8.044 2.733,7.828 2.991,7.809 C3.22,7.339 3.332,6.946 3.385,6.545 C3.45,6.056 3.429,5.539 3.39,4.809 C3.383,4.672 3.432,4.538 3.527,4.438 C3.621,4.339 3.752,4.282 3.889,4.282 Z M8.405,5.331 C8.606,5.381 8.746,5.561 8.746,5.767 L8.746,8.625 C8.746,8.873 8.545,9.075 8.296,9.075 C8.048,9.075 7.846,8.873 7.846,8.625 L7.846,7.568 L7.17,8.836 C7.073,9.019 6.864,9.111 6.664,9.061 C6.463,9.011 6.323,8.831 6.323,8.625 L6.323,5.767 C6.323,5.519 6.524,5.317 6.773,5.317 C7.021,5.317 7.223,5.519 7.223,5.767 L7.223,6.824 L7.899,5.556 C7.996,5.374 8.205,5.281 8.405,5.331 Z M9.472,5.317 C9.72,5.317 9.922,5.519 9.922,5.767 L9.922,6.746 L10.521,6.746 L10.521,5.767 C10.521,5.519 10.723,5.317 10.971,5.317 C11.22,5.317 11.421,5.519 11.421,5.767 L11.421,8.625 C11.421,8.873 11.22,9.075 10.971,9.075 C10.723,9.075 10.521,8.873 10.521,8.625 L10.521,7.646 L9.922,7.646 L9.922,8.625 C9.922,8.873 9.72,9.075 9.472,9.075 C9.223,9.075 9.022,8.873 9.022,8.625 L9.022,5.767 C9.022,5.519 9.223,5.317 9.472,5.317 Z M4.377,6.675 C4.327,7.054 4.235,7.421 4.083,7.807 L4.828,7.807 L4.828,5.282 L4.414,5.282 C4.434,5.78 4.435,6.232 4.377,6.675 Z"

const definition = {
  prefix: "fas",
  iconName: "square-serbian-dinar-sign",
  icon: [14, 14, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facSquareSerbianDinarSign }
