const pathData1 =
  "M1.7339 1.52613C1.73391 1.16714 1.44291 0.876114 1.08392 0.876099C0.724937 0.876083 0.43391 1.16709 0.433895 1.52607L0.433594 12.4735C0.433594 12.8325 0.724609 13.1235 1.08359 13.1235C1.44258 13.1235 1.73359 12.8325 1.73359 12.4735V8.91552L2.47644 8.17267L4.85024 12.7717C5.01489 13.0907 5.40697 13.2158 5.72597 13.0511C6.04497 12.8865 6.17009 12.4944 6.00544 12.1754L3.44135 7.20776L5.63966 5.00945C5.89351 4.7556 5.89351 4.34405 5.63966 4.09021C5.38582 3.83637 4.97427 3.83637 4.72043 4.09021L1.73366 7.07697L1.7339 1.52613ZM8.23457 8.2017C8.23457 7.51077 8.52641 6.74593 9.03068 6.15562C9.53127 5.56963 10.1954 5.2054 10.9173 5.2054C11.2762 5.2054 11.5673 4.91438 11.5673 4.5554C11.5673 4.19641 11.2762 3.9054 10.9173 3.9054C9.83973 3.9054 8.91236 4.40188 8.23457 5.10004V4.55558C8.23457 4.1966 7.94356 3.90558 7.58457 3.90558C7.22559 3.90558 6.93457 4.1966 6.93457 4.55558L6.93457 12.4738C6.93457 12.8328 7.22559 13.1238 7.58457 13.1238C7.94356 13.1238 8.23457 12.8328 8.23457 12.4738V8.2017Z"

const definition = {
  prefix: "fas",
  iconName: "kr-sign",
  icon: [12, 14, [], null, pathData1],
}

export { definition as facKrSign }
