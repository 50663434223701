const pathData1 =
  "M11.375 2.1875C11.6156 2.1875 11.8125 2.38437 11.8125 2.625V11.375C11.8125 11.6156 11.6156 11.8125 11.375 11.8125H2.625C2.38437 11.8125 2.1875 11.6156 2.1875 11.375V2.625C2.1875 2.38437 2.38437 2.1875 2.625 2.1875H11.375ZM2.625 0.875C1.65977 0.875 0.875 1.65977 0.875 2.625V11.375C0.875 12.3402 1.65977 13.125 2.625 13.125H11.375C12.3402 13.125 13.125 12.3402 13.125 11.375V2.625C13.125 1.65977 12.3402 0.875 11.375 0.875H2.625Z"
const pathData2 =
  "M4.14104 3.49365C4.46595 3.49365 4.72935 3.75705 4.72935 4.08196V6.62047L5.86822 5.4816C6.09797 5.25185 6.47046 5.25185 6.70021 5.4816C6.92996 5.71135 6.92996 6.08385 6.70021 6.31359L5.69107 7.32274L6.9178 9.61564C7.07107 9.90213 6.96308 10.2586 6.67659 10.4119C6.39011 10.5652 6.03361 10.4572 5.88033 10.1707L4.83484 8.21655L4.72935 8.32919V9.89317C4.72935 10.2181 4.46595 10.4815 4.14104 10.4815C3.81613 10.4815 3.55273 10.2181 3.55273 9.89317V4.08196C3.55273 3.75705 3.81613 3.49365 4.14104 3.49365Z"
const pathData3 =
  "M9.85966 6.50375C9.15595 6.50375 8.56002 7.04076 8.56002 7.85513V9.90535C8.56002 10.2303 8.29663 10.4937 7.97171 10.4937C7.6468 10.4937 7.38341 10.2303 7.38341 9.90535V5.91545C7.38341 5.59054 7.6468 5.32715 7.97171 5.32715C8.22551 5.32715 8.44177 5.48786 8.52428 5.71307C8.91169 5.46605 9.37118 5.32713 9.85966 5.32713C10.1846 5.32713 10.448 5.59053 10.448 5.91544C10.448 6.24035 10.1846 6.50375 9.85966 6.50375Z"

const definition = {
  prefix: "fas",
  iconName: "square-kr-sign",
  icon: [14, 14, [], null, `${pathData1} ${pathData2} ${pathData3}`],
}

export { definition as facSquareKrSign }
