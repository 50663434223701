const pathData1 =
  "M15.9057 1.49992C15.9057 1.1894 15.6861 0.922251 15.3815 0.862192C15.0768 0.802133 14.7722 0.965941 14.6544 1.25322L11.9248 7.9071L9.35081 1.26504C9.23774 0.973275 8.93283 0.803478 8.62525 0.860992C8.31767 0.918506 8.09473 1.18701 8.09473 1.49992V12.4999C8.09473 12.8589 8.38574 13.1499 8.74473 13.1499C9.10371 13.1499 9.39473 12.8589 9.39473 12.4999V4.97604L11.3015 9.89649C11.3977 10.1445 11.6353 10.309 11.9013 10.3116C12.1673 10.3142 12.408 10.1544 12.509 9.90831L14.6057 4.79707V12.4999C14.6057 12.8589 14.8968 13.1499 15.2557 13.1499C15.6147 13.1499 15.9057 12.8589 15.9057 12.4999V1.49992ZM0.750977 0.866272C0.368164 0.866272 0.0947266 1.16705 0.0947266 1.52252V7.64752V12.46C0.0947266 12.8428 0.368164 13.1163 0.750977 13.1163C1.10645 13.1163 1.40723 12.8428 1.40723 12.46V8.30377H3.2666L4.93457 12.7061C5.07129 13.0342 5.4541 13.2256 5.78223 13.0889C6.11035 12.9522 6.30176 12.5694 6.16504 12.2413L4.5791 8.11237C6.02832 7.59283 7.09473 6.22565 7.09473 4.58502C7.09473 2.53424 5.42676 0.866272 3.37598 0.866272H0.750977ZM3.37598 6.99127H1.40723V2.17877H3.37598C4.68848 2.17877 5.78223 3.27252 5.78223 4.58502C5.78223 5.92487 4.68848 6.99127 3.37598 6.99127Z"

const definition = {
  prefix: "fas",
  iconName: "ringgit-sign",
  icon: [14, 14, [], null, pathData1],
}

export { definition as facRinggitSign }
