const facUsers = {
  prefix: "fas",
  iconName: "users-custom",
  icon: [
    18,
    14,
    [],
    null,
    "M4.188,4.375 C3.607,4.375 3.051,4.145 2.641,3.734 C2.23,3.324 2,2.768 2,2.188 C2,1.607 2.23,1.051 2.641,0.641 C3.051,0.23 3.607,0 4.188,0 C4.768,0 5.324,0.23 5.734,0.641 C6.145,1.051 6.375,1.607 6.375,2.188 C6.375,2.768 6.145,3.324 5.734,3.734 C5.324,4.145 4.768,4.375 4.188,4.375 Z M5.023,2.202 C5.023,1.726 4.638,1.341 4.162,1.341 C3.686,1.341 3.301,1.726 3.301,2.202 C3.301,2.678 3.686,3.063 4.162,3.063 C4.638,3.063 5.023,2.678 5.023,2.202 Z" +
      "M14.25,4.375 C13.67,4.375 13.113,4.145 12.703,3.734 C12.293,3.324 12.062,2.768 12.062,2.188 C12.062,1.607 12.293,1.051 12.703,0.641 C13.113,0.23 13.67,0 14.25,0 C14.83,0 15.387,0.23 15.797,0.641 C16.207,1.051 16.438,1.607 16.438,2.188 C16.438,2.768 16.207,3.324 15.797,3.734 C15.387,4.145 14.83,4.375 14.25,4.375 Z M15.105,2.202 C15.105,1.726 14.719,1.341 14.243,1.341 C13.767,1.341 13.382,1.726 13.382,2.202 C13.382,2.678 13.767,3.063 14.243,3.063 C14.719,3.063 15.105,2.678 15.105,2.202 Z" +
      "M0.25,8.168 C0.25,6.557 1.557,5.25 3.168,5.25 L4.335,5.25 C4.77,5.25 5.183,5.343 5.555,5.515 C5.522,5.715 5.503,5.917 5.503,6.125 C5.503,7.17 5.959,8.107 6.687,8.75 L0.832,8.75 C0.512,8.75 0.25,8.488 0.25,8.168 Z M1.799,7.601 L4.457,7.601 C4.309,7.251 4.221,6.734 4.261,6.457 C4.142,6.399 3.772,6.399 3.627,6.399 L3.204,6.399 C2.442,6.399 1.799,7.048 1.799,7.601 Z" +
      "M17.168,8.75 L11.316,8.75 C12.041,8.107 12.5,7.17 12.5,6.125 C12.5,5.917 12.484,5.712 12.448,5.515 C12.82,5.346 13.233,5.25 13.668,5.25 L14.832,5.25 C16.443,5.25 17.75,6.557 17.75,8.168 C17.75,8.488 17.49,8.75 17.168,8.75 Z M16.207,7.601 C16.207,7.048 15.565,6.399 14.803,6.399 L14.38,6.399 C14.234,6.399 13.865,6.399 13.745,6.457 C13.786,6.734 13.698,7.251 13.55,7.601 Z" +
      "M9,8.75 C8.655,8.75 8.314,8.682 7.995,8.55 C7.677,8.418 7.388,8.225 7.144,7.981 C6.9,7.737 6.707,7.448 6.575,7.13 C6.443,6.811 6.375,6.47 6.375,6.125 C6.375,5.78 6.443,5.439 6.575,5.12 C6.707,4.802 6.9,4.513 7.144,4.269 C7.388,4.025 7.677,3.832 7.995,3.7 C8.314,3.568 8.655,3.5 9,3.5 C9.345,3.5 9.686,3.568 10.005,3.7 C10.323,3.832 10.612,4.025 10.856,4.269 C11.1,4.513 11.293,4.802 11.425,5.12 C11.557,5.439 11.625,5.78 11.625,6.125 C11.625,6.47 11.557,6.811 11.425,7.13 C11.293,7.448 11.1,7.737 10.856,7.981 C10.612,8.225 10.323,8.418 10.005,8.55 C9.686,8.682 9.345,8.75 9,8.75 Z M9,4.812 C8.828,4.812 8.657,4.846 8.498,4.912 C8.338,4.978 8.194,5.075 8.072,5.197 C7.95,5.319 7.853,5.463 7.787,5.623 C7.721,5.782 7.688,5.953 7.688,6.125 C7.688,6.297 7.721,6.468 7.787,6.627 C7.853,6.787 7.95,6.931 8.072,7.053 C8.194,7.175 8.338,7.272 8.498,7.338 C8.657,7.404 8.828,7.438 9,7.438 C9.172,7.438 9.343,7.404 9.502,7.338 C9.662,7.272 9.806,7.175 9.928,7.053 C10.05,6.931 10.147,6.787 10.213,6.627 C10.279,6.468 10.312,6.297 10.312,6.125 C10.312,5.953 10.279,5.782 10.213,5.623 C10.147,5.463 10.05,5.319 9.928,5.197 C9.806,5.075 9.662,4.978 9.502,4.912 C9.343,4.846 9.172,4.812 9,4.812 Z" +
      "M7.395,9.625 L10.605,9.625 C12.618,9.625 14.25,11.257 14.25,13.27 C14.25,13.675 13.922,14 13.52,14 L4.48,14 C4.075,14 3.75,13.672 3.75,13.27 C3.75,11.257 5.382,9.625 7.395,9.625 Z M10.605,10.938 L7.395,10.938 C6.309,10.938 5.396,11.681 5.136,12.688 L12.864,12.688 C12.607,11.681 11.693,10.938 10.605,10.938 Z",
  ],
}

export { facUsers }
