const pathData1 =
  "M11.375 2.1875C11.6156 2.1875 11.8125 2.38437 11.8125 2.625V11.375C11.8125 11.6156 11.6156 11.8125 11.375 11.8125H2.625C2.38437 11.8125 2.1875 11.6156 2.1875 11.375V2.625C2.1875 2.38437 2.38437 2.1875 2.625 2.1875H11.375ZM2.625 0.875C1.65977 0.875 0.875 1.65977 0.875 2.625V11.375C0.875 12.3402 1.65977 13.125 2.625 13.125H11.375C12.3402 13.125 13.125 12.3402 13.125 11.375V2.625C13.125 1.65977 12.3402 0.875 11.375 0.875H2.625Z"
const pathData2 =
  "M9.57415 4.32019C9.57415 3.98882 9.30552 3.72019 8.97415 3.72019C8.64278 3.72019 8.37415 3.98882 8.37415 4.32019V6.86499L7.69659 7.20365C7.40018 7.3518 7.28 7.71218 7.42815 8.00859C7.5763 8.305 7.93669 8.42519 8.2331 8.27703L8.37415 8.20653V9.65032C8.37415 9.9817 8.64278 10.2503 8.97415 10.2503C9.30552 10.2503 9.57415 9.9817 9.57415 9.65032V7.61592L10.2763 7.28402C10.5759 7.14242 10.704 6.78476 10.5624 6.48517C10.4208 6.18558 10.0631 6.0575 9.76354 6.19911L9.57415 6.28862V4.32019ZM4.2207 5.1616C3.88933 5.1616 3.6207 5.43023 3.6207 5.7616C3.6207 6.09297 3.88933 6.3616 4.2207 6.3616H6.01658L3.74517 9.31372C3.60577 9.49489 3.58144 9.73954 3.68241 9.94462C3.78338 10.1497 3.99211 10.2796 4.2207 10.2796H7.23528C7.56665 10.2796 7.83528 10.011 7.83528 9.6796C7.83528 9.34823 7.56665 9.0796 7.23528 9.0796H5.4394L7.71081 6.12748C7.85021 5.94631 7.87455 5.70166 7.77357 5.49658C7.67261 5.29149 7.46387 5.1616 7.23528 5.1616H4.2207Z"

const definition = {
  prefix: "fas",
  iconName: "square-polish-zloty-sign",
  icon: [14, 14, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facSquarePolishZlotySign }
