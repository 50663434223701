const pathData1 =
  "M11.375 2.1875C11.6156 2.1875 11.8125 2.38437 11.8125 2.625V11.375C11.8125 11.6156 11.6156 11.8125 11.375 11.8125H2.625C2.38437 11.8125 2.1875 11.6156 2.1875 11.375V2.625C2.1875 2.38437 2.38437 2.1875 2.625 2.1875H11.375ZM2.625 0.875C1.65977 0.875 0.875 1.65977 0.875 2.625V11.375C0.875 12.3402 1.65977 13.125 2.625 13.125H11.375C12.3402 13.125 13.125 12.3402 13.125 11.375V2.625C13.125 1.65977 12.3402 0.875 11.375 0.875H2.625Z"
const pathData2 =
  "M7.82793 2.89573C8.05124 2.94685 8.19081 3.16932 8.13968 3.39262L8.09191 3.60125C8.2479 3.62244 8.40443 3.65263 8.55981 3.69254L8.67088 3.20747C8.72201 2.98417 8.94448 2.8446 9.16778 2.89573C9.39108 2.94685 9.53065 3.16932 9.47952 3.39262L9.34307 3.9886C9.49628 4.06759 9.64506 4.15858 9.78749 4.26239C10.0549 4.45729 10.1137 4.83207 9.91876 5.09947C9.72386 5.36687 9.34909 5.42564 9.08169 5.23074C9.07513 5.22596 9.06854 5.22121 9.06192 5.2165L8.13424 9.26811C8.48187 9.18931 8.81814 9.04764 9.09207 8.85677C9.36356 8.6676 9.73699 8.73433 9.92616 9.00582C10.1153 9.2773 10.0486 9.65074 9.77711 9.83991C9.22039 10.2278 8.52501 10.4645 7.8469 10.5231L7.78518 10.7926C7.73405 11.0159 7.51158 11.1555 7.28828 11.1044C7.06498 11.0532 6.92541 10.8308 6.97654 10.6075L7.00154 10.4983C6.84348 10.4743 6.68815 10.4395 6.53645 10.3947L6.44534 10.7926C6.39421 11.0159 6.17174 11.1555 5.94844 11.1044C5.72514 11.0532 5.58556 10.8308 5.63669 10.6075L5.76155 10.0622C4.70667 9.45506 3.96825 8.32778 3.96825 7.04281C3.96825 5.2077 5.47191 3.71289 7.24728 3.57326L7.33103 3.20747C7.38216 2.98417 7.60463 2.8446 7.82793 2.89573ZM6.95952 4.83007C5.94859 5.08329 5.16652 5.99948 5.16652 7.04281C5.16652 7.7409 5.51574 8.38458 6.04786 8.8117L6.95952 4.83007ZM6.80454 9.22383C6.95449 9.27318 7.11034 9.30768 7.27009 9.32539L8.29225 4.86114C8.13952 4.81997 7.98207 4.79166 7.82296 4.77588L6.80454 9.22383Z"

const definition = {
  prefix: "fas",
  iconName: "square-colon-sign",
  icon: [14, 14, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facSquareColonSign }
