const pathData1 =
  "M11.1674 1.56236C11.1674 1.20337 10.8764 0.912354 10.5174 0.912354C10.1584 0.912354 9.86743 1.20337 9.86743 1.56236V7.07707L8.17873 7.92112C7.85763 8.08162 7.72742 8.47204 7.88792 8.79315C8.04842 9.11425 8.43884 9.24445 8.75995 9.08396L9.86743 8.53041V12.3783C9.86743 12.7373 10.1584 13.0283 10.5174 13.0283C10.8764 13.0283 11.1674 12.7373 11.1674 12.3783V7.89058L12.9173 7.06348C13.2419 6.91008 13.3806 6.52261 13.2272 6.19806C13.0738 5.8735 12.6863 5.73475 12.3618 5.88816L11.1674 6.45268V1.56236ZM1.35997 3.83714C1.00098 3.83714 0.709966 4.12816 0.709966 4.48714C0.709966 4.84613 1.00098 5.13714 1.35997 5.13714H6.1569L0.844806 12.0412C0.693792 12.2375 0.667425 12.5025 0.77681 12.7247C0.886194 12.9469 1.11232 13.0876 1.35997 13.0876H7.47715C7.83614 13.0876 8.12715 12.7966 8.12715 12.4376C8.12715 12.0786 7.83614 11.7876 7.47715 11.7876H2.68022L7.99231 4.88352C8.14333 4.68725 8.16969 4.42222 8.06031 4.20004C7.95092 3.97786 7.7248 3.83714 7.47715 3.83714H1.35997Z"

const definition = {
  prefix: "fas",
  iconName: "polish-zloty-sign",
  icon: [14, 14, [], null, pathData1],
}

export { definition as facPolishZlotySign }
