const pathData1 =
  "M-0.000976562 5.59507C-0.000976562 5.23608 0.273699 4.94507 0.612528 4.94507L15.387 4.94507C15.7258 4.94507 16.0005 5.23608 16.0005 5.59507C16.0005 5.95405 15.7258 6.24507 15.387 6.24507H0.612528C0.273699 6.24507 -0.000976562 5.95405 -0.000976562 5.59507Z"
const pathData2 =
  "M-0.000976562 9.89507C-0.000976562 9.53608 0.27266 9.24507 0.610207 9.24507H8.34937C8.68692 9.24507 8.96056 9.53608 8.96056 9.89507C8.96056 10.2541 8.68692 10.5451 8.34937 10.5451H0.610207C0.27266 10.5451 -0.000976562 10.2541 -0.000976562 9.89507Z"

const definition = {
  prefix: "fas",
  iconName: "short-text",
  icon: [17, 16, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facShortText }
