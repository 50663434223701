const pathData1 =
  "M1.94141 1.36609L3.5 6.9989H5.00391L6.34375 1.39343C6.42578 1.09265 6.67188 0.873901 7 0.873901C7.30078 0.873901 7.54688 1.09265 7.62891 1.39343L8.96875 6.9989H10.4727L12.0312 1.36609C12.1406 1.01062 12.4961 0.819214 12.8516 0.901245C13.207 1.01062 13.3984 1.36609 13.3164 1.72156L11.8125 6.9989H13.3438C13.6992 6.9989 14 7.29968 14 7.65515C14 8.03796 13.6992 8.3114 13.3438 8.3114H11.457L10.2539 12.6591C10.1719 12.9325 9.89844 13.1512 9.59766 13.1239C9.29688 13.1239 9.05078 12.9325 8.96875 12.6317L7.92969 8.3114H6.04297L5.00391 12.6317C4.92188 12.9325 4.67578 13.1239 4.375 13.1239C4.07422 13.1512 3.80078 12.9325 3.71875 12.6591L2.51562 8.3114H0.65625C0.273438 8.3114 0 8.03796 0 7.65515C0 7.29968 0.273438 6.9989 0.65625 6.9989H2.16016L0.65625 1.72156C0.574219 1.36609 0.765625 1.01062 1.12109 0.901245C1.47656 0.819214 1.83203 1.01062 1.94141 1.36609ZM3.88281 8.3114L4.32031 9.87L4.67578 8.3114H3.88281ZM6.34375 6.9989H7.62891L7 4.34656L6.34375 6.9989ZM9.29688 8.3114L9.65234 9.87L10.0898 8.3114H9.29688Z"

const definition = {
  prefix: "fas",
  iconName: "won-sign",
  icon: [14, 14, [], null, pathData1],
}

export { definition as facWonSign }
